/* 4. About */
 .about-shape {
     position: absolute;
     top: 60px;
     left: -60px;
     z-index: -9;
}
 .about-front-img {
     box-shadow: 0px 16px 32px 0px rgba(136, 136, 136, 0.2);
}
 .about-title h5 {
     margin-bottom: 27px;
}
 .about-right-side {
     padding-left: 23px;
}
 .author-ava {
     margin-right: 30px;
}
 .author-desination h6 {
     color: #e12454;
     text-transform: uppercase;
     letter-spacing: 1px;
}
 .author-desination h4 {
     margin-bottom: 13px;
}
 .single-item .mv-icon {
     margin-right: 30px;
}
 .mv-title h3 {
     font-size: 24px;
     margin-bottom: 20px;
}
 .mv-title p {
     padding-right: 73px;
}
 .facalty-text p {
     line-height: 26px;
     padding-top: 25px;
     padding-right: 25px;
     padding-left: 25px;
}
 .about-text-list ul li {
     margin-bottom: 20px;
}
 .about-text-list ul li i {
     height: 40px;
     width: 40px;
     line-height: 38px;
     text-align: center;
     border-radius: 50%;
     background: #f6f5ff;
     padding-left: 3px;
     margin-right: 20px;
     transition: .4s;
     display: inline-block;
}
 .about-text-list ul li:hover i {
     background: #DF2855;
     color: #fff;
}
 .about-text p {
     padding-right: 46px;
}
 .mv-text p {
     font-size: 20px;
     color: #223645;
     margin-bottom: 0;
     padding-right: 27px;
     padding-top: 5px;
}
 .mv-single-list {
     padding: 30px 0;
     border-top: 1px solid #dedeff;
     border-bottom: 1px solid #dedeff;
}
 .mv-icon {
     margin-right: 30px;
}
 .mv-right-img img {
     padding: 10px;
     background-color: #fff;
     box-shadow: 0px 16px 32px 0px rgba(205, 203, 255, 0.4);
     border-radius: 5px;
}
 .testi-quato-icon.about-icon-white {
     float: right;
     margin: 0;
     margin-top: -49px;
     margin-right: -70px;
     position: relative;
     z-index: 1;
}
 .medical-icon-brand {
     display: inline-block;
     height: 100px;
     width: 100px;
     text-align: center;
     line-height: 100px;
     border-radius: 50%;
     background-color: rgb(255, 255, 255);
     box-shadow: 0px 16px 32px 0px rgba(136, 136, 136, 0.2);
     margin-right: 51px;
     margin-top: 48px;
     margin-left: 47px;
     z-index: -9;
}
 .medical-icon-brand-2 {
     position: absolute;
     z-index: 9;
     right: -90px;
     top: 50%;
     transform: translateY(-50%);
}
 .medical-icon-brand-3 {
     right: -106px;
     margin-top: 21px;
}
 .medical-icon-brand::before {
     position: absolute;
     content: "";
     left: -28px;
     right: 0;
     bottom: 0;
     top: -30px;
     height: 156px;
     width: 156px;
     border: 2px dashed #e6e6e6;
     border-radius: 50%;
}
 .medical-icon-brand::after {
     position: absolute;
     content: "";
     left: -55px;
     right: 0;
     bottom: 0;
     top: -58px;
     height: 210px;
     width: 210px;
     border: 2px dashed #e6e6e6;
     border-radius: 50%;
}
 .about-right-content {
     padding-right: 50px;
}
.about_left{
    position:relative;
}
.about-front-img img{
    width: 100%;
    height: 85%;
}
.appoinment-section{
    background: url(../img/bg/appointment.jpg);
    background-repeat: no-repeat;
}

/* Add these styles to your CSS file or create a new one */

/* Add these styles to your CSS file or create a new one */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Set a maximum width if desired */
}


//pop//

.service-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  transition: 0.3s ease-in-out;
}

.service-popup.open {
  display: block;
}

.popup-content {
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 10px;
  color: #ce1b28;
}

.popup-content p {
  margin-bottom: 20px;
}

.close-btn {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  color: #333;
}

.popup-content button {
  //background-color: #4caf50;
  //color: black;
  //border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}


.view-more-button {
  cursor: pointer;
}

.close-bt {
  background-color:  #ce1b28;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.close-bt:hover {
  background-color: seagreen;
}

.background-blur {
  position: relative;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
  z-index: 9998;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
}


.info-button {
  font-size: 18px;
  display: inline-block;
  background: gray;
  color: white;
  border-radius: 50%;
  text-align: center;
  width: 18px;
  height: 18px;
  transition: 0.3s;
}

.info-button:hover {
  background-color: beige; /* Change background color on hover */
}
.title-icon{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}


//new popup//

/* Add this CSS in your stylesheet or in the same file as your component */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  text-align: center;
}

.popup h2 {
  color: #333;
}

.popup p {
  color: #666;
}

.popup button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.popup button:hover {
  background: #0056b3;
}

/* You may need to adjust these styles based on your overall design */
.service-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.hidden {
  display: none;
}

/* Add this CSS to your stylesheets or style tag in the component */
.accordion-button {
  background-color: beige; /* Green background color */
  color: white;
  padding: 15px 20px; /* Padding for better visual appearance */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.accordion-button:hover {
  background-color: black;
  color: white;

}

/* Add this class to create an arrow icon for better visual indication */
//.accordion-button::after {
//  content: '\25BC'; /* Unicode character for a downward-pointing triangle */
//  font-size: 20px;
//  margin-left: 5px;
//  display: inline-block;
//  transition: transform 0.3s ease;
//}
//
///* Rotate the arrow when the accordion is open */
//.accordion-button.active::after {
//  transform: rotate(180deg);
//}
//.black-background {
//  background-color: #223645;
//  padding: 0 45px;
//  position: fixed;
//  top: 0px;
//  left: 0px;
//  width: 100%;
//  z-index: 9999999;
//  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
//  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
//  transition: all 0.3s ease-out 0s;
//}

.hero-sec{
  color: white;
}

.para{
  line-height: 35px;
}

.hero-tag{
  color: #43BDBD
}
//.hero-slider-btn{
//  padding-top: 25px;
//}